<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="shortener" :translate-params="{id: short.id}">Edit short url  #%{id}</translate>
    </h1>
    <Form
        disable-short-edit
        :data="short"
        @save="onSave"
        :save_text="$pgettext('form','Save')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import Form from "@/domain/shortener/components/Form.vue";

export default {
  name: 'EditShortUrl',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchShort(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'shortener.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('shortener', [
      'short',
    ]),
  },
  methods: {
    ...mapActions('shortener', [
      'fetchShort',
      'updateShort',
    ]),
    async onSave(data) {
      await this.updateShort({id: this.short.id,data: data})
      await this.$router.push({name: 'shortener.list'})
      throw new SuccessNotification(this.$pgettext('shortener', 'Short Url updated!'))
    }
  }
}
</script>

<style scoped>

</style>